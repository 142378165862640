<div class="outer-border">
  <div class="mid-border">
    <div class="inner-border">
      <img class="corner-decoration corner-left-top" src="/assets/img/corner-deco.png" width="1920" height="1807" alt="deco-corner">
      <img class="corner-decoration corner-right-top" src="/assets/img/corner-deco.png" width="1920" height="1807" alt="deco-corner">
      <img class="corner-decoration corner-right-bottom" src="/assets/img/corner-deco.png" width="1920" height="1807" alt="deco-corner">
      <img class="corner-decoration corner-left-bottom" src="/assets/img/corner-deco.png" width="1920" height="1807" alt="deco-corner">

      <!-- Page Content -->
      <div class="pt-4 pb-16">
        <div class="w-full lg:w-2/3 px-4 md:px-8 mx-auto font-fondamento">
          <h3 class="text-3xl md:text-4xl font-medium title-font my-16 text-center">Verhaltenskodex</h3>
          <div class="grid-list text-xl">
            <ng-container *ngFor="let rule of rules; let i = index;">
              <div class="ordinal">{{i + 1}}.</div>
              <div class="rule">{{rule}}</div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
