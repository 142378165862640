import { Component, OnInit } from '@angular/core';
import { NgFor } from '@angular/common';

@Component({
  selector: 'app-code-of-conduct',
  templateUrl: './code-of-conduct.component.html',
  styleUrls: ['./code-of-conduct.component.css'],
  imports: [NgFor],
})
export class CodeOfConductComponent implements OnInit {
  rules: string[] = [];

  constructor() {
    this.rules.push('Begrüssung beim Betreten und Verlassen der Matte');
    this.rules.push('Trainingspartner beim Betreten der Matte in Gürtelreihenfolge begrüssen');
    this.rules.push('Bitte den Instruktor / Sensei um Erlaubnis, bevor du die Matte betrittst oder verlässt');
    this.rules.push('Sei pünktlich - 5 Minuten Toleranz. Wenn du zu spät ankommst, warte auf die Genehmigung zum Betreten der Matten');
    this.rules.push(
      'Rede nicht während des Unterrichts über Sachen, die nichts mit BJJ zu tun haben, um mehr davon zu profitieren. Ziehe den Kimono nicht vor dem Ende des Unterrichts ab und halte ihn immer gebunden. Halte deinen Kimono sauber und ohne Löcher',
    );
    this.rules.push('Schimpfwörter und obszöne Gesten sind verboten. Verhalte dich immer respektvoll');
    this.rules.push(
      'Ziehe deine Schuhe aus, bevor du auf die Matten trittst, und trage Sandalen/Flipflop ausserhalb der Matten. Gehe nicht barfuss von der Matte, insbesondere ins Badezimmer.',
    );
    this.rules.push('Verwende Rash Guard unter dem Gi. Halte die Fingernägel und Fussnägel gekürzt');
    this.rules.push(
      'Langes Haar sollte mit einem Gummiband befestigt werden, nicht mit Metallklammern. Entferne Ringe, Ohrringe, Armbänder, Ketten und Piercings, bevor du die Matte betrittst. Benutze dein Handy nicht während des Unterrichts',
    );
    this.rules.push('Nicht auf der Matte essen oder Kaugummi kauen');
    this.rules.push('Prahle nicht damit, deinen Gegner im Training zu finalisieren. Trainiere lieber deine Schwachstellen');
    this.rules.push('Halte die Matte immer sauber. Wenn der Instruktor / Sensei eine Technik zeigt, führe sie aus, bis er dich auffordert damit aufzuhören');
  }

  ngOnInit(): void {}
}
