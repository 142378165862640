import { Component, OnInit } from '@angular/core';
import { SeoService } from '../core/service/seo.service';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { CodeOfConductComponent } from './code-of-conduct/code-of-conduct.component';
import { HeroComponent } from './hero/hero.component';
import { TeamComponent } from './team/team.component';
import { LogoHistoryComponent } from './logo-history/logo-history.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  imports: [TestimonialsComponent, CodeOfConductComponent, HeroComponent, TeamComponent, LogoHistoryComponent],
})
export class HomeComponent implements OnInit {
  constructor(private seoService: SeoService) {}

  ngOnInit(): void {
    this.seoService.updateMetaTag(
      'description',
      'Lernen Sie Brazilian Jiu-Jitsu als Selbstverteidigung von erfahrenen Lehrern an der ACA ' +
        'BJJ in Zürich, Oerlikon. Vereinbaren Sie Ihre erste Gratislektion!',
    );
    this.seoService.createLinkForCanonicalURL('');
  }
}
