<app-hero></app-hero>
<div class="mt-20"></div>
<app-team></app-team>
<div class="mt-20"></div>
<app-logo-history></app-logo-history>
<div class="mt-20"></div>
<app-code-of-conduct></app-code-of-conduct>
<div class="mt-20"></div>
<app-testimonials></app-testimonials>

<!--<div class="flex justify-center items-center">-->
<!--  <div class="w-1/2 h-1/2">-->
<!--    <img src="assets/img/under-construction.jpg">-->
<!--  </div>-->
<!--</div>-->
