<h3 class="text-3xl md:text-4xl font-medium title-font mt-32 mb-14 px-4 text-center">Was unsere Mitglieder über uns sagen</h3>

<section class="p-4 lg:p-10 mb-24 text-gray-700">

  <div class="grid lg:grid-cols-3 gap-10 text-center">
    <div class="flex items-center justify-center block-min-width">
      <div class="block rounded-lg shadow-lg bg-white max-w-md">
        <div class="overflow-hidden rounded-t-lg h-16 belt-color-brown">
          <div class="stripe base h-16"></div>
        </div>
        <div class="w-24 -mt-12 overflow-hidden border border-2 border-white rounded-full mx-auto bg-white">
          <picture>
            <source type="image/webp" srcset="/assets/img/testimonialA2.webp">
            <source type="image/png" srcset="/assets/img/testimonialA2.jpeg">
            <img src="/assets/img/testimonialA2.webp" alt="acabjj testimonial A" width="300" height="300">
          </picture>
        </div>
        <div class="pt-2 pr-6 pb-6 pl-6">
          <h4 class="text-2xl font-semibold mb-4">Mami von Kerem</h4>
          <hr />
          <p class="mt-4 italic">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="quote-left" class="w-6 pr-2 inline-block" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path fill="currentColor" d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"></path>
            </svg>
            Unser Grosser hatte nach der ersten Stunde entschieden:
            "Ich will mein Leben lang Jiu-Jitsu bei Ando machen und werde eine Gold-Medaille holen!"
          </p>
          <p class="mt-2 italic">
            Als Mutter weiss ich ihn in guten Händen und wir freuen uns auf jedes Training.
            Besonders gefällt mir der gute Ausgleich zwischen Disziplin und Humor mit denen Ando den Kindern begegnet. Danke Ando.
          </p>
          <div class="lg:mb-8"></div>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-center block-min-width">
      <div class="block rounded-lg shadow-lg bg-white max-w-md">
<!--        <div class="block rounded-lg shadow-lg bg-white max-w-md striped">-->
        <div class="overflow-hidden rounded-t-lg h-16 belt-color-brown">
          <div class="stripe base h-16"></div>
<!--          <div class="stripe stripe-1 h-16"></div>-->
<!--          <div class="stripe stripe-2 h-16"></div>-->
<!--          <div class="stripe stripe-3 h-16"></div>-->
        </div>
        <div class="w-24 -mt-12 overflow-hidden border border-2 border-white rounded-full mx-auto bg-white">
          <picture>
            <source type="image/webp" srcset="/assets/img/testimonialB2.webp">
            <source type="image/png" srcset="/assets/img/testimonialB2.jpeg">
            <img src="/assets/img/testimonialB2.webp" alt="acabjj testimonial B" width="300" height="300">
          </picture>
        </div>
        <div class="pt-2 pr-6 pb-6 pl-6">
          <h4 class="text-2xl font-semibold mb-4">Fabricio</h4>
          <hr />
          <p class="mt-4 italic">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="quote-left" class="w-6 pr-2 inline-block" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path fill="currentColor" d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"></path>
            </svg>
            Ich mache schon seit Jahren BJJ und habe dadurch viel Disziplin im Alltag, sowie auch im Umgang bei Stress Situationen gelernt.
            Ich als blue belt kann es kaum warten auf die Competitions zu gehen, mit stolz ACA BJJ zu repräsentieren und natürlich die Gold-Medalie ins Gym zu bringen.
            Finde das Training sehr gut aufgebaut, sodass die Kids, Erwachsenen, Beginner und natürlich auch die Erfahrenen davon profitieren.
            Wer Selbsverteidigung lernen möchte ist hier an der richtige Adresse.
          </p>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-center block-min-width">
      <div class="block rounded-lg shadow-lg bg-white max-w-md">
        <div class="overflow-hidden rounded-t-lg h-16 belt-color-brown">
<!--          <div class="stripe base h-16"></div>-->
<!--          <div class="stripe stripe-1 h-16"></div>-->
        </div>
        <div class="w-24 -mt-12 overflow-hidden border border-2 border-white rounded-full mx-auto bg-white">
          <picture>
            <source type="image/webp" srcset="/assets/img/testimonialC2.webp">
            <source type="image/png" srcset="/assets/img/testimonialC2.jpg">
            <img src="/assets/img/testimonialC2.webp" alt="acabjj testimonial C" width="300" height="300">
          </picture>
        </div>
        <div class="pt-2 pr-6 pb-6 pl-6">
          <h4 class="text-2xl font-semibold mb-4">Victoria (10)</h4>
          <hr />
          <p class="mt-4 italic">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="quote-left" class="w-6 pr-2 inline-block" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path fill="currentColor" d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"></path>
            </svg>
            Ich habe vor genau 4 Jahren mit BJJ angefangen - und möchte in ein paar Jahren das Training für die Kids übernehmen.
            Ich möchte das, was ich gelernt habe, den Kindern weitergeben.
            Heutzutage fühle ich mich viel sicherer im Umgang mit Bullying und kann mich auch gut wehren gegen andere Kinder in der Schule.
            Mein Ziel ist auch mal black belt zu werden und weitere Medaillen für mich zu gewinnen.
            Ich liebe diesen Sport und möchte bald wieder bei einem Wettkampf mitmachen! Oss
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
