<div class="h-full">
  <section>
    <svg
      data-name="Triangle"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 320"
      preserveAspectRatio="none"
      class="svg absolute hidden lg:block"
      style="height: 20rem; width: 100%; z-index: -10; overflow: hidden">
      <defs>
        <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
          <stop stop-color="#d3a4a4" offset="0%"></stop>
          <stop stop-color="#ffffff" offset="100%"></stop>
        </linearGradient>
      </defs>
      <path
        d="M2.6905829906463623 117.4887924194336C2.6905829906463623 117.4887924194336 382.0627746582031 242.1524658203125 382.0627746582031 242.1524658203125C382.0627746582031 242.1524658203125 796.4125366210938 113.9013442993164 796.4125366210938 113.9013442993164 "
        fill="url(#sw-gradient-0)"
        ></path>
    </svg>

    <div class="p-6 md:my-12 md:px-12 text-gray-800 text-center md:text-left">
      <div class="container mx-auto xl:px-16">
        <div class="grid lg:grid-cols-2 gap-12 flex items-center">
          <div class="lg:mb-32">
            <h1 class="text-4xl md:text-5xl font-bold tracking-tight mb-4 lg:mb-6">Brazilian Jiu-Jitsu in Zürich,
              Oerlikon<br/> <span class="text-orange-800">für Klein und Gross</span></h1>
            <h2 class="md:pr-24 md:mr-4 py-3 text-orange-900 font-medium text-md leading-snug" data-mdb-ripple="true"
                 data-mdb-ripple-color="light">
              Willkommen bei ACA BJJ, Deiner Anlaufstelle für Brazilian Jiu-Jitsu in der Region Zürich Oerlikon.
              Unsere Schule bietet Kurse für Kinder, Erwachsene und Wettkampfsportler jeder Erfahrungsstufe.
            </h2>
            <div class="mt-6 flex justify-center md:justify-start">
              <a routerLink="/message"
                 class="p-1.5 trial-lesson-button bg-orange-800 hover:bg-amber-700 text-gray-50 font-bold">
                Gratis Probetraining
              </a>
            </div>
          </div>
          <div class="mb-8 lg:mb-0">
            <picture>
              <source media="(max-width: 649px)" type="image/webp" srcset="/assets/img/hero_1m.webp">
              <source media="(min-width: 650px)" type="image/webp" srcset="/assets/img/hero_1.webp">
              <source media="(max-width: 649px)" type="image/png" srcset="/assets/img/hero_1m.jpg">
              <source media="(min-width: 650px)" type="image/png" srcset="/assets/img/hero_1.jpg">
              <img src="/assets/img/hero_2.webp" class="w-full rounded-lg shadow-lg" alt="aca bjj main hero"
                   width="1200" height="800">
            </picture>
            <div class="text-right text-6xl font-semibold">
              <div><span class="text-orange-800/60">a</span><span class="text-orange-800/30">ndo</span></div>
              <div><span class="text-orange-800/60">c</span><span class="text-orange-800/30">ardoso</span></div>
              <div class="tracking-widest mt-2">
                <span class="text-orange-800/60">a</span><span class="text-orange-800/40">cademy</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<div class="h-full">
<div class="lg:mt-24 p-6 xl:px-16">
  <div class="container mx-auto">
    <div class="flex flex-wrap text-center md:text-left">
      <div class="w-full md:w-3/5 text-lg">
        <div class="md:px-6 mb-8">
          Unsere erfahrenen Lehrer bieten individuelle Unterstützung und Anleitung, um Dir dabei zu helfen,
          Deine Techniken und Fähigkeiten im Brazilian Jiu-Jitsu zu verbessern.
          Unsere Kurse sind so aufgebaut, dass sowohl Anfänger als auch Fortgeschrittene profitieren können.
        </div>
        <div class="mt-4 p-6 bg-orange-800/70 rounded-lg text-white">
          Wir legen grossen Wert darauf, eine sichere und unterstützende Umgebung zu schaffen,
          in der sich unsere Schüler und Schülerinnen wohl fühlen und ihr volles Potenzial entfalten können.
          Unser Ziel ist es, jedem Schüler und jeder Schülerin die Möglichkeit zu geben,
          seine / ihre Fähigkeiten im Brazilian Jiu-Jitsu zu verbessern und das Selbstbewusstsein zu stärken.
        </div>
      </div>
      <div class="w-full md:w-2/5 md:px-16 flex flex-col-reverse md:flex-col">
        <hr class="h-2 mt-8 md:mt-2 md:mb-8 rounded bg-orange-800">
        <div class="text-lg md:leading-8 mt-8 md:mt-0">
          Für unsere Wettkampfsportler bieten wir spezielle Kurse und Trainingseinheiten,
          um dich auf Wettkämpfe vorzubereiten.
          Unsere Lehrer haben langjährige Erfahrung im Wettkampfsport und können Dir dabei helfen,
          Deine Techniken und Taktiken auf ein höheres Niveau zu bringen.
        </div>
      </div>
    </div>

    <div class="my-14 md:my-20 md:mx-6 text-2xl font-medium text-gray-500 leading-10 flex justify-center">
      <div class="md:w-4/5 text-center">
        Besuche uns noch heute und erlebe die Vorteile des Brazilian Jiu-Jitsu für dich selbst.
        Wir freuen uns darauf, Dich in unserer Schule begrüssen zu dürfen – komm vorbei!
      </div>
    </div>
    <hr class="h-2 rounded bg-orange-800">
  </div>
</div>
</div>
