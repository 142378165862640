import { Component, Input } from '@angular/core';
import { InstructorCard } from '../../../core/domain/instructor-card';
import { NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'app-instructor-card',
  templateUrl: './instructor-card.component.html',
  styleUrls: ['./instructor-card.component.css'],
  imports: [NgFor, NgIf],
})
export class InstructorCardComponent {
  @Input() instructorCard?: InstructorCard;
}
