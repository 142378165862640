<div *ngIf="instructorCard" class="flex justify-center m-6">
  <div class="flex flex-col md:flex-row md:max-w-xl rounded-lg bg-white shadow-lg">
    <img class="w-full h-96 md:h-auto object-cover md:w-52 rounded-t-lg md:rounded-none md:rounded-l-lg"
         src="/assets/img/instructor-{{instructorCard.image}}" alt="bjj instructor" width="256" height="384"/>
    <div class="p-6 flex flex-col justify-start">
      <h5 class="text-gray-900 text-xl font-medium mb-2">{{instructorCard.instructor}}</h5>
      <p *ngFor="let paragraph of instructorCard.paragraphs" class="text-gray-700 text-base mb-4">
        {{paragraph}}
      </p>
      <p class="text-gray-600 text-xs">{{instructorCard.classes}}</p>
    </div>
  </div>
</div>
