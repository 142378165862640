import { Component } from '@angular/core';
import { InstructorCard } from '../../core/domain/instructor-card';
import { InstructorCardFactory } from './instructor-card/instructor-card-factory';
import { InstructorCardComponent } from './instructor-card/instructor-card.component';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css'],
  imports: [InstructorCardComponent],
})
export class TeamComponent {
  instructorAndo: InstructorCard;
  // instructorHenrique: InstructorCard;
  // instructorBrunno: InstructorCard;

  constructor() {
    this.instructorAndo = InstructorCardFactory.ando();
    // this.instructorHenrique = InstructorCardFactory.henrique();
    // this.instructorBrunno = InstructorCardFactory.brunno();
  }
}
