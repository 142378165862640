<div class="container my-32 px-6 md:px-12 mx-auto">
  <section>
    <div class="text-center">
      <h3 class="text-3xl md:text-4xl font-bold mb-4">Unser Logo - <span class="text-orange-800">Unsere Philosophie</span></h3>
      <p>Unser Logo ist eine gleichmässige Mischung unter dem Nameninitilialen, dem Schild und einem japanischen Tempel.</p>
    </div>

    <div class="flex flex-wrap md:flex-nowrap justify-center md:justify-around items-center mt-4 md:mt-16">

      <div class="w-88 h-auto p-12">
        <img src="/assets/img/logo-history.png" alt="ACA BJJ Logo concept" width="938" height="1058"/>
      </div>

      <div class="w-full md:w-3/5 legend-grid gap-8 lg:gap-16">
        <div>
          <h3 class="text-xl md:text-2xl font-medium title-font mb-2 text-gray-800">ACA</h3>
          <div class="h-1 w-20 bg-gray-800 rounded"></div>
        </div>
        <div>
          <p class="text-gray-500"><b>A</b>ndo <b>C</b>ardoso <b>A</b>cademy</p>
        </div>
        <div>
          <h3 class="text-xl md:text-2xl font-medium title-font mb-2 text-gray-800 whitespace-nowrap">Das Schild</h3>
          <div class="h-1 w-20 bg-gray-800 rounded"></div>
        </div>
        <div>
          <p class="text-gray-500">
            Steht für Schutz im Sinne von Verteidigung, Selbstverteidigung und aber auch Kontrolle, damit man danach
            angreifen kann. Beim Brazilian Jiu-Jitsu ist nicht anderst.
          </p>
        </div>
        <div>
          <h3 class="text-xl md:text-2xl font-medium title-font mb-2 text-gray-800 whitespace-nowrap">Der Tempel</h3>
          <div class="h-1 w-20 bg-gray-800 rounded"></div>
        </div>
        <div>
          <p class="text-gray-500">
            Das Brazilian Jiu-Jitsu ist eine Abwandlung und Weiterentwicklung der japanischen Kampfkunst Judo und Jiu Jitsu,
            die den Schwerpunkt auf Bodenkampf legt, wobei im Training zusätzlich Wurftechniken aus dem Stand unterrichtet werden
          </p>
        </div>
      </div>
    </div>
  </section>
</div>
