import { InstructorCard } from '../../../core/domain/instructor-card';

export class InstructorCardFactory {

  static ando(): InstructorCard {
    return {
      instructor: 'Ando Cardoso',
      image: 'ando-1.jpg',
      paragraphs: [
        'Unser Instruktor Ando Cardoso besuchte vom weissen Gurt bis zum Black Belt die weltberühmte und erfolgreiche Carlson Gracie BJJ Schule. Mit Leidenschaft und Herzblut unterrichtet er Brazilian Jiu Jitsu, die am schnellsten wachsende Kampfkunst weltweit. ',
        'Seine 14 jährige Erfahrung in BJJ ermöglicht es ihm sein Wissen erfolgreich den Kids und Erwachsenen weiterzugeben.',
        'Wer ihn im Unterricht erlebt hat, kann bezeugen – seine Begeisterung für diese Kampfkunst ist ansteckend!'
      ],
      classes: 'Bjj Gi, Bjj Kids, Bjj Women, Bjj Drills'
    } as InstructorCard;
  }

  static henrique(): InstructorCard {
    return {
      instructor: 'Henrique Santos',
      image: 'henrique.jpg',
      paragraphs: [
        'Prof. Ryan has a passion for teaching and spreading his knowledge of this fast growing martial art. ',
        'Prof. Ryan has extensive teaching experience and besides dedicating his time to his students, has instructed for numerous organizations, schools and the United States Army.'
      ],
      classes: 'Bjj No-Gi'
    } as InstructorCard;
  }

  static brunno(): InstructorCard {
    return {
      instructor: 'Brunno Alves',
      image: 'brunno-1.jpg',
      paragraphs: [
        'Brunno ist seit über 17 Jahren ein angesehener Profi. Sein praktischer und effizienter Unterricht ist immer an die Bedürfnisse und Einschränkungen des Schülers angepasst und führt zu raschen und ausdrucksstarken Ergebnissen schon nach wenigen Lektionen. ',
        'Wer sich in Sachen Gesundheit, Sicherheit und Fitness verbessern möchte, ist bei ihm an der richtigen Adresse.',
        'Überzeug dich selbst bei einem Probetraining, wie er auf die Details schaut und einen sehr profissioneller Trainer ist.'
      ],
      classes: 'Chinesisches Sanda Box'
    } as InstructorCard;
  }
}
